var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.interaction.origin === 'REF' && _vm.refData)?_c('div',{staticClass:"data-reply-container"},[_c('div',{staticClass:"data-reply-content"},[_c('div',{staticClass:"btn data-reply-message",attrs:{"role":"button"}},[_c('span',{class:_vm.refData.mode === 'INPUT' ? 'bg-color-info' : 'bg-color-agent'}),_c('div',{staticClass:"data-reply-content-text"},[_c('div',{staticClass:"data-reply-text"},[_c('div',{staticClass:"data-reply-responsable"},[_c('span',{style:(_vm.refData.mode === 'INPUT' ? 'color: #0c90c0' : 'color: #ee985f')},[_vm._v(_vm._s(_vm.refData.mode === "INPUT" ? ("+ " + (_vm.getConsumer(_vm.interaction))) : "Tú"))])]),_c('div',{staticClass:"data-reply-description"},[(_vm.refData.text.toString() && !_vm.refData.fileurl)?_c('span',[_vm._v(_vm._s(_vm.refData.text.toString()))]):(
                !_vm.refData.text.toString() &&
                _vm.refData.fileurl &&
                _vm.refData.mimetype === 'application/pdf'
              )?_c('span',{staticClass:"documentText"},[_c('font-awesome-icon',{attrs:{"icon":"paperclip"}}),_vm._v(" "+_vm._s(_vm.refData.filename.toString())+" ")],1):_vm._e(),(
                (_vm.refData.fileurl && _vm.refData.mimetype === 'image/png') ||
                _vm.refData.mimetype === 'image/webp' ||
                _vm.refData.mimetype === 'image/jpeg' ||
                _vm.refData.mimetype === 'image/gif'
              )?_c('div',{staticClass:"reply-image-container"},[_c('div',{staticClass:"reply-image-content"},[_c('div',{class:_vm.loading ? 'loading' : 'reply-image-item'},[(_vm.loading)?_c('div',{staticClass:"d-flex align-items-center justify-content-center"},[_c('span',{staticClass:"text-primary"},[_c('font-awesome-icon',{attrs:{"icon":"circle-notch","spin":"","size":"lg"}})],1)]):_vm._e(),_c('div',{style:({ backgroundImage: ("url(" + _vm.imageUrl + ")") }),attrs:{"alt":_vm.refData.filename}})])])]):_vm._e(),(_vm.refData.mimetype === 'audio/ogg; codecs=opus')?_c('div',[_c('audio',{attrs:{"controls":"","src":_vm.imageUrl}})]):_vm._e()])])])])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }