import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import {
  ConversationNewEntryQueue,
  Conversation,
  Interaction,
  MessageType,
  InteractionOrigin,
  BusinessStatus,
} from "@/app/store/modules/conversation/conversation.models";
import { VueWizard } from "@/vue-wizard";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import ConversationMessage from "../conversationMessage/ConversationMessage.vue";
import { store, storeTypes } from "@/app/store";
import * as moment from "moment";
import { PerfectScrollbar } from "vue2-perfect-scrollbar";
import eventBus from "@/main";
@Component({
  name: "conversation-content",
  components: {
    PulseLoader,
    ConversationMessage,
    PerfectScrollbar,
  },
})
export default class ConversationContent extends VueWizard {
  @Prop() readonly currentConversation!: Conversation;
  @Prop() readonly currentCloseConversations!: Conversation[];
  @Prop() readonly isClosed!: boolean;
  @Prop() readonly EventBus!: Vue;
  @Prop() readonly profilePicture!: string;
  @Prop() public businessStatuses!: BusinessStatus[];
  @Prop() public project!: any;

  public newMessage = false;
  public maxHeightSubstract = 0;
  public sendMessage: string[] = [];
  public loading: boolean = false;
  public replyText: string = "";
  public conversationReply: string[] | any = "";
  public emptyText: object = { emptyText: "Sin conversaciones" };
  public visible: boolean = false;
  public interact: string[] = [];
  public selected_consumers: string[] = [];
  public selected_consumers_id!: string[];
  public refData: any = "";

  statusColorStyle(status: BusinessStatus): any {
    if (status) {
      return {
        "--label-bg-color-r": this.hexToRgb(status.color)
          ? (this.hexToRgb(status.color) as any).r
          : 0,
        "--label-bg-color-g": this.hexToRgb(status.color)
          ? (this.hexToRgb(status.color) as any).g
          : 0,
        "--label-bg-color-b": this.hexToRgb(status.color)
          ? (this.hexToRgb(status.color) as any).b
          : 0,
      };
    } else {
      return {};
    }
  }

  hexToRgb(hex: string): any {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null;
  }

  getBusinessStatus(status: string): BusinessStatus | undefined {
    return this.businessStatuses.find(
      (businessStatus: BusinessStatus) => businessStatus.name === status
    );
  }

  formattedDate(date: Date): string {
    return moment.default(date).format("LT");
  }

  send_share_message(currentId: string[], currentSendMessage: any) {
    this.loading = true;
    var uniqueId = currentId.filter(function (elem, index, self) {
      return index === self.indexOf(elem);
    });
    uniqueId.forEach(async (element: string, index: number) => {
      await store
        .dispatch(
          storeTypes.conversation.actions.shareSendMessage({
            id: element,
            type: currentSendMessage.type,
            text: currentSendMessage.text,
            referenceId: currentSendMessage._id,
            filename: currentSendMessage.filename,
            fileurl: currentSendMessage.fileurl,
            mimetype: currentSendMessage.mimetype,
            origin: InteractionOrigin.FRW,
          })
        )
        .then(() => {
          this.visible = false;
        })
        .finally(() => {
          if (index === 1)
            this.EventBus.$emit(
              "sentMessage",
              this.$store.state.agent.agent._id
            );
          this.loading = false;
        });
    });
  }

  get totalPages(): number {
    return store.state.agent!.totalPages;
  }

  get actualPage(): number {
    return store.state.agent!.actualPage;
  }

  get agentOpenConversations(): any {
    return store.state.agent!.agentOpenConversations;
  }

  created(): void {
    this.EventBus.$on("showAttachmentSite", this.onShowAttachmentSite);
    this.EventBus.$on("sendingMessage", this.onSendingMessage);
    this.EventBus.$on("sentMessage", this.onSentMessage);
    eventBus.$on(
      "showConversationContentLoading",
      this.onShowConversationContentLoading
    );
  }

  get interactions(): Interaction[] {
    return store.state.agent!.interactions;
  }

  async mounted(): Promise<any> {
    await this.chargeInteractions(this.actualPage);
  }

  @Watch("currentConversation")
  async changeInteractions(): Promise<any> {
    //await store.commit(storeTypes.agent.mutations.setInteractions([]));
    await store.commit(storeTypes.agent.mutations.setActualPage(0));
    await store.commit(storeTypes.agent.mutations.setTotalPages(0));
    setTimeout(
      async () => await this.chargeInteractions(this.actualPage),
      1000
    );
  }

  async chargeInteractions(page: number): Promise<any> {
    this.loading = true;
    await this.loadInteractions(page);
    this.maxHeightSubstract = this.currentConversation.isClosed ? 0 : 160;
    setTimeout(() => {
      this.$nextTick(() => {
        this.$el.scrollTop = this.$el.scrollHeight - this.$el.clientHeight;
        this.loading = false;
      });
    }, 2000);
  }

  // downScroll(): void {apg
  //   if (!this.loading) {
  //     this.$nextTick(
  //       () =>
  //         (this.$el.scrollTop = this.$el.scrollHeight - this.$el.clientHeight)
  //     );
  //   }
  // }

  beforeDestroy(): void {
    // store.commit(storeTypes.agent.mutations.setInteractions([]));
    store.commit(storeTypes.agent.mutations.setActualPage(0));
    store.commit(storeTypes.agent.mutations.setTotalPages(0));
  }

  /**
   * este metodo es el que procesa el boton cargar mas...
   */
  async loadMoreInteractions(): Promise<any> {
    this.loading = true;
    const initialScrollHeight = this.$el.scrollHeight;
    let page = this.actualPage;
    await this.loadInteractions(++page);
    setTimeout(() => {
      this.$nextTick(() => {
        this.$el.scrollTop = this.$el.scrollHeight - initialScrollHeight;
        this.loading = false;
      });
    }, 2000);
  }

  async loadInteractions(page: number): Promise<any> {
    await store.dispatch(
      storeTypes.agent.actions.loadActualInteractions({
        conversationId: this.currentConversation._id,
        page,
      })
    );
  }

  @Watch("maxHeightSubstract")
  changeInMessage(newmaxHeightSubstract: number): void {
    // this.$el.setAttribute("style", `max-height: calc(100vh - ${newmaxHeightSubstract}px);`);
  }

  getTopic(item: ConversationNewEntryQueue): string {
    let topic = item.topic
      ? item.topic
      : item.currentTopic
      ? item.currentTopic
      : "";
    if (topic.includes("_")) topic = topic.split("_")[1];
    return topic;
  }

  getUserPicture(item: any): any {
    let profileurl = null;

    let conversation: Conversation;
    if (item.variables) {
      conversation = item;
    } else {
      conversation = item.conversation;
    }

    if (
      conversation != undefined &&
      conversation.variables.CONSUMER_PROFILE_PICTURE
    ) {
      profileurl = conversation.variables.CONSUMER_PROFILE_PICTURE.value;
    }
    return profileurl;
  }

  getUserName(item: any): any {
    let userName = "";
    let conversation: Conversation;
    if (item.variables) {
      conversation = item;
    } else {
      conversation = item.conversation;
    }
    if (
      conversation != undefined &&
      conversation.variables != undefined &&
      conversation.variables.CONSUMER_NAME
    ) {
      userName = conversation.variables.CONSUMER_NAME.value;
    }
    return userName;
  }

  formatUpdatedDate(date: any): string {
    moment.locale("es");
    return moment.default(date).calendar();
  }

  getConsumer(item: Conversation): string {
    let consumer = item.currentConsumer
      ? item.currentConsumer
      : item.consumer
      ? item.consumer
      : "";
    if (consumer.includes("@")) consumer = consumer.split("@")[0];
    return consumer;
  }

  onShowAttachmentSite(size: number): void {
    this.maxHeightSubstract = size + 160;
    this.$nextTick(() => {
      this.$el.scrollTop = this.$el.scrollHeight - this.$el.clientHeight;
    });
  }

  onSendingMessage(id: string): void {
    this.sendMessage = this.sendMessage.concat(id);
    this.$nextTick(() => {
      this.$el.scrollTop = this.$el.scrollHeight - this.$el.clientHeight;
    });
  }

  onSentMessage(id: string): void {
    this.sendMessage = this.sendMessage.filter((el) => el !== id);
  }

  /**
   * escucha el evento ShowConversationContentLoading
   */
  onShowConversationContentLoading(): void {
    this.loading = true;
  }
  get user(): any {
    return store.state.user!.user;
  }

  get initials(): string {
    const firstName =
      this.user && this.user.name && this.user.name.firstName
        ? this.user.name.firstName.substr(0, 1).toUpperCase()
        : "";
    const lastName =
      this.user && this.user.name && this.user.name.lastName
        ? this.user.name.lastName.substr(0, 1).toUpperCase()
        : "";
    return `${firstName}${lastName}`;
  }

  showAvatar(index: number): boolean {
    return true;
    const actualMode = this.interactions[index].mode;
    return !(
      this.interactions[index - 1] &&
      this.interactions[index - 1].mode === actualMode
    );
  }

  getDateFormated(index: number): string {
    const messageTime = moment.default(this.interactions[index].createdAt);
    const todayDate = moment.default().startOf("day");
    const yesterdayDate = moment.default().startOf("day").subtract(1, "day");
    let dateLabel;

    if (messageTime.isAfter(todayDate)) {
      dateLabel = "Hoy";
    } else if (messageTime.isAfter(yesterdayDate)) {
      dateLabel = "Ayer";
    } else {
      dateLabel = messageTime.format("DD/MM/YY");
    }

    return dateLabel;
  }
  newDate(index: number): boolean {
    const actualMessageDate = moment
      .default(this.interactions[index].createdAt)
      .startOf("day");
    const lastMessageDate = moment
      .default(this.interactions[index - 1].createdAt)
      .startOf("day");
    return actualMessageDate.isAfter(lastMessageDate);
  }

  setReply(interactionReply: Interaction, mySelf: boolean) {
    this.$root.$emit("sendReply", interactionReply, mySelf);
  }
  setConsumers(consumer: string, consumer_id: string) {
    let indexOfSelected = this.selected_consumers.indexOf(consumer);
    if (indexOfSelected > -1) {
      this.selected_consumers.splice(indexOfSelected, 1);
    } else {
      this.selected_consumers.push(consumer);
    }
    let indexOfId = this.selected_consumers_id.indexOf(consumer_id);
    if (indexOfId > -1) {
      this.selected_consumers_id.splice(indexOfId, 1);
    } else {
      if (Array.isArray(consumer_id)) {
        consumer_id = consumer_id.toString();
      }
      this.selected_consumers_id.push(consumer_id);
    }
  }
  @Watch("visible")
  handleCloseSelected() {
    this.selected_consumers = [];
    this.selected_consumers_id = [];
  }
}
