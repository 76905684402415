import { render, staticRenderFns } from "./MessageText.vue?vue&type=template&id=ca6133e8&scoped=true&"
import script from "./message-text.ts?vue&type=script&lang=ts&"
export * from "./message-text.ts?vue&type=script&lang=ts&"
import style0 from "./MessageText.vue?vue&type=style&index=0&id=ca6133e8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ca6133e8",
  null
  
)

export default component.exports