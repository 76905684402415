import { Component, Prop } from "vue-property-decorator";
import { VueWizard } from "@/vue-wizard";
import { Interaction, InteractionOrigin, Conversation } from "@/app/store/modules/conversation/conversation.models";
import { BASE_URL_MANAGER, BASE_URL_ORCHESTATOR } from "@/config";
import axios, { AxiosError, AxiosResponse } from 'axios';
import Axios from 'axios';

@Component({
    name: 'message-response'
})

export default class MessageResponse extends VueWizard {
    @Prop() readonly interaction!: Interaction;

    public loading = false;
    public refData: any = "";
    public imageUrl: string = "";

    mounted() {
        this.referenceInteraction();
    }

    getConsumer(item: Conversation): string {
        let consumer = item.currentConsumer
            ? item.currentConsumer
            : item.consumer
                ? item.consumer
                : "";
        if (consumer.includes("@")) consumer = consumer.split("@")[0];
        return consumer;
    }

    referenceInteraction() {
        if (this.interaction.origin === InteractionOrigin.REF && this.interaction.referenceId) {
            Axios(`${BASE_URL_ORCHESTATOR}/conversation/interactions/${this.interaction.referenceId}`).then(async (res: AxiosResponse) => {
                this.refData = res.data;
                await this.dataUrlToFile(this.refData.fileurl).then((res: string) => {
                    if (res) {
                        this.imageUrl = res;
                    }
                });
            }).catch((err: AxiosError) => {
                throw err;
            })
        }
    }

    async dataUrlToFile(url?: string | any): Promise<string> {
        this.loading = true;
        let tempUrl = '';
        if (url) {
            await axios.post(`${BASE_URL_MANAGER}/company/getfile`, {
                urlfile: url
            }).then((response) => {
                tempUrl = response.data.file;
                this.loading = false;
            });
        }
        return tempUrl;
    }
}