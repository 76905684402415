import { Component, Prop } from "vue-property-decorator";
import { VueWizard } from "@/vue-wizard";
import { Interaction } from "@/app/store/modules/conversation/conversation.models";
import * as moment from 'moment';
import { BASE_URL_MANAGER } from "@/config";
import axios from 'axios';
import MessageResponse from "../message-response/MessageResponse.vue";

@Component({
  name: 'message-image',
  components: { MessageResponse }
})
export default class ImageMessage extends VueWizard {
  public visible = false;
  @Prop() readonly message!: Interaction;
  @Prop() readonly createdAt!: Date;
  @Prop() readonly mode!: string;

  public fileUrl = '';
  public loading = false;
  public rotate = 0;

  changeRotate() {
    if (this.rotate < 3) {
      this.rotate++;
    } else {
      this.rotate = 0;
    }
  }

  changeRotateMin() {
    if (this.rotate === 0) {
      this.rotate = 3;
    } else {
      this.rotate--;
    }
  }

  mounted(): void {
    this.dataUrlToFile();
  }

  get CreatedAt() {
    return moment.default(this.message.createdAt).format('LT');
  }

  get Mode() {
    return this.message.mode === "OUTPUT";
  }

  dataUrlToFile(): void {
   

    let urlOrBase64: any = this.message.fileurl;
    this.fileUrl = urlOrBase64;
    /* this.loading = true;
     axios.post(`${BASE_URL_MANAGER}/company/getfile`, {
       urlfile: this.message.fileurl
     }).then((response) => {
 
       this.fileUrl = response.data.file;
       
       this.loading = false;
     });*/
  }

  getSubString(message: any): string {
    let newString='';
    if(message.filename!=undefined){
      if (newString.length > 25) {
        let extention = newString.substring(newString.length - 4, newString.length);
        return newString.substring(0, 26) + '...' + extention
      }
    }
  
    return newString
  }

}