import { Component, Prop } from "vue-property-decorator";
import { VueWizard } from "@/vue-wizard";
import { Interaction } from "@/app/store/modules/conversation/conversation.models";
import * as moment from 'moment';
import { BASE_URL_MANAGER } from "@/config";
import axios from "axios";
import ResponseMessage from "../message-response/MessageResponse.vue";

@Component({
  name: "message-audio",
  components: { ResponseMessage }
})
export default class AudioType extends VueWizard {
  @Prop() readonly message!: Interaction;
  @Prop() readonly createdAt!: Date;
  @Prop() readonly mode!: string;
  @Prop() readonly project!: any;

  public fileUrl = '';

  mounted(): void {
    this.dataUrlToFile();

  }

  get CreatedAt() {
    return moment.default(this.message.createdAt).format('LT');
  }

  get Mode() {
    return this.message.mode === "OUTPUT";
  }

  dataUrlToFile(): void {
    console.log('this.message.fileur', this.message.fileurl);
  //  console.log('this.project.wpBusinessAPiKey', this.project.wpBusinessAPiKey);
    let fileurl: any = this.message.fileurl;
    this.fileUrl = fileurl;



    // Solicitud GET (Request).
    /*fetch(fileurl,{
      mode: 'cors',
      headers: {
        'Access-Control-Allow-Origin':'*',
        'Authorization': `AccessKey ${this.project.wpBusinessAPiKey}`
      }
    })
      // Exito
      .then(response => response.json())  // convertir a json
      .then(json => console.log(json))    //imprimir los datos en la consola
      .catch(err => console.log('Solicitud fallida', err)); // Capturar errores

*/

    //message bird, con message bird tengo que buscar el audio con el token de autenticcion para poder escucharlo
    /* if (this.project.wpBusinessAPiKey != undefined) {
       axios.get(fileurl, {
         headers: {
            'Authorization': `AccessKey ${this.project.wpBusinessAPiKey}`
          }
        
       }).then((response: any) => {
         console.log('response', response);
         this.fileUrl = response;
       }).catch(e => {
         console.log('e', e);
       });
     }*/

  }

}