import { render, staticRenderFns } from "./ConversationContent.vue?vue&type=template&id=265be779&scoped=true&"
import script from "./conversation-content.ts?vue&type=script&lang=ts&"
export * from "./conversation-content.ts?vue&type=script&lang=ts&"
import style0 from "./ConversationContent.vue?vue&type=style&index=0&id=265be779&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "265be779",
  null
  
)

export default component.exports