import {Component, Prop} from "vue-property-decorator";
import {VueWizard} from "@/vue-wizard";
import {Interaction} from "@/app/store/modules/conversation/conversation.models";
import * as moment from 'moment';
import {BASE_URL_MANAGER} from "@/config";
import axios from 'axios';
import ResponseMessage from "../message-response/MessageResponse.vue";

@Component({
    name:"message-file",
    components: { ResponseMessage }
})
export default class FileType extends VueWizard {
  @Prop() readonly message!: Interaction;
  @Prop() readonly createdAt!: Date;
  @Prop() readonly mode!: string;
    public fileUrl = '';

    get extentionIcon(){
        let icon = 'file-alt';
        let splittedName = this.message.filename.split('.');
        let extention = splittedName[splittedName.length-1].toLowerCase();
        switch(extention){
            case 'pdf':
                icon = 'file-pdf';
                break;
            case 'docx':
                icon = 'file-word';
                break;
            case 'doc':
                icon = 'file-word';
                break;
            case 'xlsx':
                icon = 'file-excel';
                break;
            case 'csv':
                icon = 'file-excel';
                break;
        }
        return icon;
    }

    get iconStyle(){
        let style = {color: 'black', size: '50px'}
        let splittedName = this.message.filename.split('.');
        let extention = splittedName[splittedName.length-1].toLowerCase();
        switch(extention){
            case 'pdf':
                style.color= 'red';
                break;
            case 'docx':
                style.color= 'blue';
                break;
            case 'doc':
                style.color= 'blue';
                break;
            case 'xlsx':
                style.color= 'green';
                break;
            case 'csv':
                style.color= 'green';
                break;
        }
        return style;
    }

    get CreatedAt(){
        return moment.default(this.message.createdAt).format('LT');
    }

    get Mode(){
        return this.message.mode === "OUTPUT";
    }

    download() {
        axios.post(`${BASE_URL_MANAGER}/company/getfile`, {
            urlfile: this.message.fileurl
        }).then(async (response) => {
            this.fileUrl = response.data.file;
            const link = document.createElement("a");
            link.download = this.message.filename;
            link.href = response.data.file;
            link.target = "_blank";
            await link.click();
            this.fileUrl = "";
            document.removeChild(link);
        });
    }

    getSubString(newString: string): string{
        if(newString.length > 80){
            let extention = newString.substring(newString.length-4, newString.length);
            return newString.substring(0,81) + '...' + extention
        }
        return newString
    }
}