import { Component, Prop } from "vue-property-decorator";
import { VueWizard } from "@/vue-wizard";
import {
  Interaction,
  MessageType,
} from "@/app/store/modules/conversation/conversation.models";
import Message from "../../messages/message-text/MessageText.vue";
import ImageMessage from "../../messages/message-image/MessageImage.vue";
import FileType from "../../messages/message-file/MessageFile.vue";
import AudioType from "../../messages/message-audio/MessageAudio.vue";

@Component({ name: "conversation-message" })
export default class ConversationMessage extends VueWizard {
  @Prop() readonly message!: Interaction;
  @Prop() readonly project!: any;
  get currentMessage(): any {
    switch (this.message.type) {
      case MessageType.MESSAGE:
        return Message;
      case MessageType.IMAGE:
        return ImageMessage;
      case MessageType.FILE:
        return FileType;
      case MessageType.AUDIO:
        return AudioType;
      default:
        return;
    }
  }
}
